import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nichts gefunden" />
    <div
      style={{
        maxWidth: "1600px",
        margin: "0 auto",
        padding: "5rem 1rem 6rem",
      }}
    >
      <h1>Nichts gefunden</h1>
      <p>Diese Seite existiert leider nicht.</p>
      <Link href="/">Zurück zur Startseite</Link>
    </div>
  </Layout>
)

export default NotFoundPage
